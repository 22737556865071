import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Dashboard } from 'src/app/api/models/dashboard';
import { DashboardActions } from 'src/app/dashboard/dashboard.actions';

@Component({
  selector: 'app-curated-lists',
  templateUrl: './curated-lists.component.html',
  styleUrls: ['./curated-lists.component.sass'],
})
export class CuratedListsComponent implements OnInit {
  public dashboard$: Observable<Dashboard>;
  public ghostTeasersCount = Array(7);
  public ghostTeaserRows = Array(7);

  page = 'Yoga-Home';

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit(): void {
    this.dashboard$ = this.store.select(state => state.dashboard.dashboard);
    this.store.dispatch(new DashboardActions.FetchDashboard());
  }
}
